import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AdyenService } from '../services/adyen.service';
import { BasketService } from '../services/basket.service';
import { Store } from '../store';

@Component({
    selector: 'app-adyen-paylink',
    templateUrl: './adyen-paylink.component.html',
    styleUrls: ['./adyen-paylink.component.css']
})
export class AdyenPaylinkComponent implements OnInit {
    @Input() paymentData = undefined;

    @Output() adyenResult = new EventEmitter;

    processing = false;
    paymentLinkData;
    emailEdit = false;

    constructor(
        private adyenService: AdyenService,
        private basketService: BasketService,
        private store: Store,
    ) { }

    ngOnInit(): void {
        console.log(this.paymentData);
    }

    toggleEmailEdit() {
        if (!this.emailEdit) {
            this.emailEdit = true;
        } else {
            this.emailEdit = false;
            // this.usernameAlt = undefined;
            // this.saveAltEmail();
        }
    }
    saveAltEmail() {
        if (this.paymentData.isBooking) {
            const basket = this.store.selectForLocal('basket');
            basket.user.usernameAlt = this.paymentData.shopper.emailAlt;
            this.store.set('basket', basket);
            this.basketService.saveBasketToDB(basket);
        }
        this.emailEdit = false;
    }
    cancelAltEmail() {
        this.paymentData.shopper.emailAlt = ''
        this.emailEdit = false;
    }

    createPaymentLink(rrId) {
        this.processing = true;
        this.paymentData.sendBookingLink = true;

        const linkData = {
            shopper: this.paymentData.shopper,
            lineItems: this.paymentData.lineItems,
            userId: this.paymentData.user.id,
            hotelId: this.paymentData.hotel.id,
            hotelName: this.paymentData.hotel.name,
            roomId: this.paymentData.room.id,
            value: this.paymentData.value,
            reference: this.paymentData.reference,
            expiryHours: 2,
            metadata: this.paymentData.metadata,
        }

        console.log(linkData)
        this.adyenService.createPaymentLink(linkData).subscribe(data => {
            data.metadata = this.paymentData.metadata;
            this.paymentData.result = data;

            console.log(data);

            if (this.paymentData.sendBookingLink) {
                const linkData = this.paymentData.metadata;
                linkData.payUrl = data.url;
                linkData.payUrlId = data.id;
                linkData.expiresAt = data.expiresAt;
                linkData.userId = this.paymentData.user.id;
                linkData.hotelId = this.paymentData.hotel.id;
                linkData.value = this.paymentData.value;
                linkData.reference = this.paymentData.reference;

                this.createBookingLink(linkData)
            }

            this.adyenResult.emit(data)
            // this.router.navigate(['/bookings/create/complete'])
        })

    }

    createBookingLink(data) {
        this.adyenService.createBookingLink(data).subscribe(data => {
            console.log(data)
        })
    }


}
